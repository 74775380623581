<!-- No longer used, was replaced by the agent directory for AOs and the new agent detail pages...  -->
<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu></q-my-stats-submenu>
        <!-- Charts-->
        <div v-bind:is="type" class="mx-5" :agent_code="agent_code" :key="agent_code + componentKey"></div>
    </v-container>
</template>
<script>
/*
Force re-rendering of this component on this view...
set a key and anytime the agentcode changes we will increment the componentKey and it will re-render the component.
 */

import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QSubmittedDrilldown from '@/components/stats/scorecards/QSubmittedDrilldown.vue';
import QCloseratioDrilldown from '@/components/stats/scorecards/QCloseratioDrilldown.vue';
import QPlacedDrilldown from '@/components/stats/scorecards/QPlacedDrilldown.vue';
import QConservedPoliciesDrilldown from '@/components/stats/scorecards/QConservedPoliciesDrilldown.vue';
import QUniqueWritersDrilldown from '@/components/stats/scorecards/QUniqueWritersDrilldown.vue';
import QALeadsDrilldown from '@/components/stats/scorecards/QALeadsDrilldown.vue';
import QBonusLeadsDrilldown from '@/components/stats/scorecards/QBonusLeadsDrilldown.vue';
import QGmrRequestedLeadsDrilldown from '@/components/stats/scorecards/QGmrRequestedLeadsDrilldown.vue';
import QUniqueLeadTakersDrilldown from '@/components/stats/scorecards/QUniqueLeadTakersDrilldown.vue';
import QTotalRecruitsDrilldown from '@/components/stats/scorecards/QTotalRecruitsDrilldown.vue';
import QUniqueBuildersDrilldown from '@/components/stats/scorecards/QUniqueBuildersDrilldown.vue';
import QNewRecruitsDrilldown from '@/components/stats/scorecards/QNewRecruitsDrilldown.vue';
import QNewWritersDrilldown from '@/components/stats/scorecards/QNewWritersDrilldown.vue';
import QRegistrationsDrilldown from '@/components/stats/scorecards/QRegistrationsDrilldown.vue';
import QuilityRxCardsSentDrilldown from '@/components/stats/scorecards/QuilityRxCardsSentDrilldown.vue';
import QuilityRxCodesUsedDrilldown from '@/components/stats/scorecards/QuilityRxCodesUsedDrilldown.vue';
import QuilityRxCompensableDrilldown from '@/components/stats/scorecards/QuilityRxCompensableDrilldown.vue';
import QHqActiveDrilldown from '@/components/stats/scorecards/QHqActiveDrilldown.vue';
import QHqActiveStaffDrilldown from '@/components/stats/scorecards/QHqActiveStaffDrilldown.vue';
import QHqLoginsDrilldown from '@/components/stats/scorecards/QHqLoginsDrilldown.vue';
import QHqLoginsStaffDrilldown from '@/components/stats/scorecards/QHqLoginsStaffDrilldown.vue';
import QHqPromotionsDrilldown from '@/components/stats/scorecards/QHqPromotionsDrilldown.vue';
import QHqBirthdaysDrilldown from '@/components/stats/scorecards/QHqBirthdaysDrilldown.vue';
import QSummitStartedDrilldown from "@/components/stats/scorecards/QSummitStartedDrilldown";
import QPhaseCompletedDrilldown from "@/components/stats/scorecards/QPhaseCompletedDrilldown";
import QOnboardingRecruitingStatusDrilldown from "@/components/stats/scorecards/QOnboardingRecruitingStatusDrilldown";

export default {
    props: ['type'],
    data() {
        return {
            componentKey: 1
        }
    },
    mounted: function() {

    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agent_code != 'undefined' && this.$route.params.agent_code != null) {
                return this.$route.params.agent_code;
            }
            return this.user.Agent.AgentCode;
        },

    },
    methods: {

    },
    watch: {
        'agent_code': function(newV) {
            this.componentKey = this.componentKey + 1
        },
    },
    components: {
        QMyStatsSubmenu,
        QSubmittedDrilldown,
        QCloseratioDrilldown,
        QPlacedDrilldown,
		QConservedPoliciesDrilldown,
        QUniqueWritersDrilldown,
        QGmrRequestedLeadsDrilldown,
        QUniqueLeadTakersDrilldown,
        QBonusLeadsDrilldown,
        QALeadsDrilldown,
        QTotalRecruitsDrilldown,
        QUniqueBuildersDrilldown,
        QNewRecruitsDrilldown,
        QNewWritersDrilldown,
        QRegistrationsDrilldown,
        QuilityRxCardsSentDrilldown,
        QuilityRxCodesUsedDrilldown,
        QuilityRxCompensableDrilldown,
        QHqActiveDrilldown,
        QHqLoginsDrilldown,
        QHqPromotionsDrilldown,
        QHqBirthdaysDrilldown,
        QHqActiveStaffDrilldown,
        QSummitStartedDrilldown,
        QHqLoginsStaffDrilldown,
        QPhaseCompletedDrilldown,
        QOnboardingRecruitingStatusDrilldown,
    }
}
</script>
<style scoped>
</style>
